<template>
  <div class="project-preferences">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="pb-10 pb-lg-15">
          <h2 class="fw-bolder text-dark">Project Preferences</h2>
        </div>
        <div class="mb-10 fv-row">
          <label class="form-label mb-3"
            >What status should potential projects have?</label
          >
          <Field
            name="projectStatuses"
            class="form-control form-control-lg form-control-solid"
            v-slot="{ field }"
            v-model="projectStatusesSelectedLocal"
          >
            <Multiselect
              @change="$emit('projectStatusesUpdated', $event)"
              valueProp="id"
              v-bind="field"
              class="multiselect-blue"
              v-model="projectStatusesSelectedLocal"
              :options="projectStatuses"
              mode="tags"
              :searchable="true"
              label="name"
              trackBy="name"
            />
          </Field>
          <ErrorMessage
            name="projectStatuses"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
        <div class="mb-10 fv-row">
          <label class="form-label mb-3"
            >What are your preferred industry verticals?</label
          >
          <Field
            class="form-control form-control-lg form-control-solid"
            name="industryVerticals"
            v-slot="{ field }"
            v-model="industrySelectedLocal"
          >
            <Multiselect
              @change="$emit('industriesUpdated', $event)"
              v-model="industrySelectedLocal"
              valueProp="id"
              class="multiselect-blue"
              v-bind="field"
              :options="allIndustries"
              mode="tags"
              :searchable="true"
              label="name"
              trackBy="name"
            />
          </Field>
          <ErrorMessage
            name="industryVerticals"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "ProjectPreferences",
  components: {
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    projectStatusesSelected: {
      type: Array,
      required: true,
    },
    industriesSelected: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const projectStatusesSelectedLocal = ref([]);
    const industrySelectedLocal = ref([]);
    const store = useStore();

    const projectStatuses = computed(
      () => store.getters["UserModule/getAllProjectStatuses"]
    );
    const allIndustries = computed(
      () => store.getters["UserModule/getAllIndustries"]
    );

    watch(
      () => props.projectStatusesSelected,
      (value) => {
        projectStatusesSelectedLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.industriesSelected,
      (value) => {
        industrySelectedLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    onMounted(async () => {
      await store.dispatch("UserModule/fetchAllProjectStatuses");
      await store.dispatch("UserModule/fetchAllIndustries");
    });

    return {
      projectStatuses,
      allIndustries,
      projectStatusesSelectedLocal,
      industrySelectedLocal,
    };
  },
};
</script>

<style scoped></style>
