<template>
  <div class="expert-about-you">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="pb-10 pb-lg-15">
          <h2 class="fw-bolder text-dark">About you</h2>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label">Profile URL(LinkedIn or XING)</label>
          <Field
            v-model="expertProfileLinkLocal"
            @input="$emit('expertProfileLinkChanged', $event.target.value)"
            name="profileUrl"
            class="form-control form-control-lg form-control-solid"
          />
          <ErrorMessage
            name="profileUrl"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
        <div className="fv-row mb-10">
          <label className="d-flex align-items-center form-label">
            <span>City</span>
          </label>
          <Field
            name="city"
            v-model="expertCityLocal"
            @input="$emit('expertCityChanged', $event.target.value)"
            class="form-control form-control-lg form-control-solid"
          />
          <ErrorMessage
            name="city"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import { ref, watch } from "vue";
export default {
  name: "AboutYou",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    expertProfileLink: {
      type: String,
      required: true,
    },
    expertCity: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const expertProfileLinkLocal = ref("");
    const expertCityLocal = ref("");

    watch(
      () => props.expertProfileLink,
      (value) => {
        expertProfileLinkLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.expertCity,
      (value) => {
        expertCityLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    return {
      expertProfileLinkLocal,
      expertCityLocal,
    };
  },
};
</script>

<style scoped></style>
