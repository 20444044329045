<template>
  <div class="expert-current-job-info">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="pb-10 pb-lg-15">
          <h2 class="fw-bolder text-dark">Current Job</h2>
        </div>
        <div className="fv-row mb-10">
          <label className="d-flex align-items-center form-label">
            <span>Company You Work For</span>
          </label>
          <Field
            @input="$emit('expertCompanyChanged', $event.target.value)"
            type="text"
            v-model="expertCompanyLocal"
            name="company"
            class="form-control form-control-lg form-control-solid"
            autocomplete="off"
          />
          <ErrorMessage
            name="company"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label">Level Of Seniority</label>
          <Field
            @change="
              $emit(
                'expertSeniorityLevelIdChanged',
                Number($event.target.value)
              )
            "
            name="levelOfSeniority"
            class="form-select form-select-lg form-select-solid"
            data-control="select2"
            data-placeholder="Select..."
            data-allow-clear="true"
            data-hide-search="true"
            as="select"
            v-model="expertSeniorityLevelIdLocal"
          >
            <option
              v-for="level in seniorityLevels"
              :key="level.id"
              :value="level.id"
            >
              {{ level.name }}
            </option>
          </Field>
          <ErrorMessage
            name="levelOfSeniority"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label">Active Industry</label>
          <Field
            @change="
              $emit('expertIndustryIdChanged', Number($event.target.value))
            "
            name="activeIndustry"
            class="form-select form-select-lg form-select-solid"
            data-control="select2"
            data-placeholder="Select..."
            data-allow-clear="true"
            data-hide-search="true"
            as="select"
            v-model="expertIndustryIdLocal"
          >
            <option
              v-for="industry in allIndustries"
              :key="industry.id"
              :value="industry.id"
            >
              {{ industry.name }}
            </option>
          </Field>
          <ErrorMessage
            name="activeIndustry"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
export default {
  name: "CurrentJob",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    expertCompany: {
      type: String,
      required: true,
    },
    expertIndustryId: {
      type: Number,
      required: true,
    },
    expertSeniorityLevelId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const expertCompanyLocal = ref("");
    const expertIndustryIdLocal = ref(null);
    const expertSeniorityLevelIdLocal = ref(null);
    const allIndustries = computed(
      () => store.getters["UserModule/getAllIndustries"]
    );
    const seniorityLevels = computed(
      () => store.getters["ExpertModule/getSeniorityLevels"]
    );

    watch(
      () => props.expertCompany,
      (value) => {
        expertCompanyLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.expertIndustryId,
      (value) => {
        expertIndustryIdLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.expertSeniorityLevelId,
      (value) => {
        expertSeniorityLevelIdLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    onMounted(async () => {
      await store.dispatch("UserModule/fetchAllIndustries");
      await store.dispatch("ExpertModule/fetchSeniorityLevels");
    });

    return {
      allIndustries,
      seniorityLevels,
      expertCompanyLocal,
      expertIndustryIdLocal,
      expertSeniorityLevelIdLocal,
    };
  },
};
</script>

<style scoped></style>
