<template>
  <div v-loading="loading" class="expert-signup-info">
    <Form
      @submit="saveExpertData"
      class="form w-100"
      :validation-schema="expertDataValidation"
    >
      <el-row :gutter="24">
        <el-col :span="12">
          <ProjectPreferences
            @projectStatusesUpdated="projectStatusesUpdated"
            @industriesUpdated="industriesUpdated"
            :project-statuses-selected="expertProjectStatusesIds"
            :industries-selected="expertIndustryIds"
          />
        </el-col>
        <el-col :span="12">
          <AboutYou
            @expertProfileLinkChanged="expertProfileLinkChanged"
            @expertCityChanged="expertCityChanged"
            :expert-city="expertCity"
            :expert-profile-link="expertProfileLink"
          />
        </el-col>
        <el-col :span="24">
          <CurrentJob
            @expertCompanyChanged="expertCompanyChanged"
            @expertIndustryIdChanged="expertIndustryIdChanged"
            @expertSeniorityLevelIdChanged="expertSeniorityLevelIdChanged"
            :expert-company="expertCompany"
            :expert-industry-id="expertIndustryId"
            :expert-seniority-level-id="expertSeniorityLevelId"
          />
        </el-col>
      </el-row>
      <el-row v-if="dataChanged">
        <el-col :span="24">
          <div class="float-end">
            <button
              @click="fetchExpertAccountInfo"
              ref="discardButton"
              type="button"
              class="btn btn-lg btn-secondary"
              style="margin-right: 1rem"
            >
              <span class="indicator-label"> Discard </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <button
              ref="submitButton"
              type="submit"
              class="btn btn-lg btn-primary"
            >
              <span class="indicator-label"> Save </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </el-col>
      </el-row>
    </Form>
  </div>
</template>

<script>
import ProjectPreferences from "../components/SingUpInfo/ProjectPreferences";
import {
  getExpertInfo,
  getExpertProjectStatuses,
  getIndustriesOfExpert,
  updateExpertInfo,
} from "../../api/expert.api";
import { Form } from "vee-validate";
import { onMounted, ref } from "vue";
import AboutYou from "../components/SingUpInfo/AboutYou";
import { useStore } from "vuex";
import * as Yup from "yup";
import CurrentJob from "../components/SingUpInfo/CurrentJob";
export default {
  name: "ExpertSignUpInfo",
  components: { CurrentJob, AboutYou, ProjectPreferences, Form },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const expertProjectStatusesIds = ref([]);
    const expertIndustryIds = ref([]);
    const dataChanged = ref(false);
    const expertProfileLink = ref(null);
    const expertCity = ref(null);
    const expertCompany = ref(null);
    const expertSeniorityLevelId = ref(null);
    const expertIndustryId = ref(null);
    const submitButton = ref(null);
    const expertDataValidation = Yup.object().shape({
      profileUrl: Yup.string().matches(
        /^$|(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)|(http(s)?:\/\/)?([\w]+\.)?xing\.com\/profile\//,
        "Enter correct url!"
      ),
      projectStatuses: Yup.array()
        .required()
        .label("Project Status")
        .min(1, "Select minimum 1 value"),
      industryVerticals: Yup.array()
        .required()
        .label("Industry Verticals")
        .min(1, "Select minimum 1 value"),
    });
    const fetchExpertAccountInfo = async () => {
      loading.value = true;
      const promises = [];
      let expertInfo;
      promises.push(
        new Promise((resolve) => {
          store
            .dispatch("UserModule/fetchUserAndSave")
            .then(() => resolve(""))
            .catch(() => resolve(""));
        })
      );
      promises.push(
        new Promise((resolve) => {
          fetchExpertProjectStatusesIds()
            .then((resp) => {
              expertProjectStatusesIds.value = resp;
              resolve("");
            })
            .catch(() => resolve(""));
        })
      );
      promises.push(
        new Promise((resolve) => {
          fetchExpertIndustryIds()
            .then((resp) => {
              expertIndustryIds.value = resp;
              resolve("");
            })
            .catch(() => resolve(""));
        })
      );
      promises.push(
        new Promise((resolve) => {
          getExpertInformation()
            .then((resp) => {
              expertInfo = resp;
              resolve("");
            })
            .catch(() => resolve(""));
        })
      );
      await Promise.all(promises);
      expertSeniorityLevelId.value = expertInfo.seniorityLevel
        ? expertInfo.seniorityLevel.id
        : null;
      expertIndustryId.value = expertInfo.industry
        ? expertInfo.industry.id
        : null;
      //here
      expertProfileLink.value =
        store.getters["ExpertModule/getExpertProfileLink"];
      expertCity.value = store.getters["ExpertModule/getExpertCity"];
      expertCompany.value = store.getters["ExpertModule/getExpertCompany"];
      dataChanged.value = false;
      loading.value = false;
    };

    const getExpertInformation = async () => {
      try {
        const { data } = await getExpertInfo();
        return data.data;
      } catch {
        return {};
      }
    };

    const fetchExpertProjectStatusesIds = async () => {
      const ids = [];
      try {
        const { data } = await getExpertProjectStatuses();
        for (const investmentRange of data.data) {
          ids.push(investmentRange.projectStatus.id);
        }
      } catch {
        //
      }
      return ids;
    };

    const fetchExpertIndustryIds = async () => {
      const ids = [];
      try {
        const { data } = await getIndustriesOfExpert();
        for (const investmentRange of data.data) {
          ids.push(investmentRange.industry.id);
        }
      } catch {
        //
      }
      return ids;
    };

    const saveExpertData = async () => {
      loading.value = true;
      try {
        await updateExpertInfo({
          city: expertCity.value,
          companyName: expertCompany.value,
          profileLink: expertProfileLink.value,
          levelOfSeniority: expertSeniorityLevelId.value,
          activeIndustry: expertIndustryId.value,
          projectStatuses: expertProjectStatusesIds.value,
          preferredIndustry: expertIndustryIds.value,
        });
        await fetchExpertAccountInfo();
        await store.dispatch("ExpertModule/fetchExpertProjectStatuses", true);
      } catch (e) {
        //
      } finally {
        loading.value = false;
      }
    };

    //Callback from Children
    const projectStatusesUpdated = (data) => {
      expertProjectStatusesIds.value = data;
      dataChanged.value = true;
    };

    const industriesUpdated = (data) => {
      expertIndustryIds.value = data;
      dataChanged.value = true;
    };

    const expertProfileLinkChanged = (data) => {
      expertProfileLink.value = data;
      dataChanged.value = true;
    };

    const expertCityChanged = (data) => {
      expertCity.value = data;
      dataChanged.value = true;
    };

    const expertCompanyChanged = (data) => {
      expertCompany.value = data;
      dataChanged.value = true;
    };

    const expertSeniorityLevelIdChanged = (data) => {
      expertSeniorityLevelId.value = data;
      dataChanged.value = true;
    };

    const expertIndustryIdChanged = (data) => {
      expertIndustryId.value = data;
      dataChanged.value = true;
    };

    onMounted(async () => {
      await fetchExpertAccountInfo();
    });

    // watch(
    //   [expertProjectStatusesIds, expertIndustryIds, expertProfileLink],
    //   () => {
    //     dataChanged.value = true;
    //   }
    // );

    return {
      expertProjectStatusesIds,
      expertIndustryIds,
      expertProfileLink,
      expertCity,
      expertCompany,
      expertIndustryId,
      expertSeniorityLevelId,
      dataChanged,
      loading,
      submitButton,
      expertDataValidation,
      //Methods
      fetchExpertAccountInfo,
      projectStatusesUpdated,
      industriesUpdated,
      expertProfileLinkChanged,
      expertCityChanged,
      expertCompanyChanged,
      expertSeniorityLevelIdChanged,
      expertIndustryIdChanged,
      saveExpertData,
    };
  },
};
</script>

<style scoped></style>
